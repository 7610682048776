<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default q-mx-lg" style="max-width: 1980px;">

    <!-- theme -->
    <div class="row">
      <div class="col-xs-12 col-md-8 gutter-xs q-my-xl">
        <q-card flat class="bg-lmsBackground">
          <q-card-section>
            <div class="row">
              <div class="col">
                <div class="text-h5">Theme</div>
                <div class="text-subtitle2">Choose your default site theme</div>
              </div>
            </div>
          </q-card-section>
          <q-card-section>
            <q-select emit-value map-options v-model="currentTheme" :options="$store.getters['themes/getThemes']" option-value="uid" option-label="label" />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-8 gutter-xs q-my-xl">
        <q-card flat class="bg-lmsBackground">
          <q-card-section>
            <div class="row">
              <div class="col">
                <div class="text-h5">Headquarters Timezone</div>
                <div class="text-subtitle2">This will be used to generate reports at midnight according to your company headquarters.</div>
              </div>
            </div>
          </q-card-section>
          <q-card-section>
            <q-select emit-value map-options :options="timeZoneOptions" v-model="timezone" label="Headquarters Timezone" />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <!-- home page filter -->
    <div class="row">
      <q-list>
        <q-item class="row items-center q-my-md">
          <q-item-section>
            <q-checkbox v-model="enableMessages" label="Enable Messages" />
          </q-item-section>
          <q-item-section avatar>
              <q-icon name="help">
               <q-tooltip class="text-h4">Allow users to send private messages to each other</q-tooltip>
              </q-icon>
            </q-item-section>
        </q-item>
        <q-item class="row items-center q-my-md">
          <q-item-section>
            <q-checkbox v-model="enableSubmittedContent" label="Enable Submitted Content" />
          </q-item-section>
          <q-item-section avatar>
              <q-icon name="help">
               <q-tooltip class="text-h4">Allow users to upload content for you to review</q-tooltip>
              </q-icon>
            </q-item-section>
        </q-item>
        <q-item class="row items-center q-my-md">
          <q-item-section>
            <q-checkbox v-model="homePageFilter" label="Show Home Page Filter" />
          </q-item-section>
          <q-item-section avatar>
              <q-icon name="help">
               <q-tooltip>Allow your company to have filterable homepages for the Entire Company, Specific Locations, and Specific User Groups</q-tooltip>
              </q-icon>
            </q-item-section>
        </q-item>
        <q-item class="row items-center q-my-md">
          <q-item-section>
            <q-checkbox v-model="enableUserTheme" label="Allow Users to Change Theme" />
          </q-item-section>
          <q-item-section avatar>
              <q-icon name="help">
               <q-tooltip>Allow users to set their own theme</q-tooltip>
              </q-icon>
            </q-item-section>
        </q-item>
      </q-list>
    </div>

    <!-- logo cropper -->
    <div class="col-12 q-pt-lg" id="cropper">
      <div class="row">
        <div class="col-12 center" id="cropperContainer">
          <img ref="editLogo" id="croppedLogo" src="" />
        </div>
      </div>
      <div class="row justify-center gutter-sm q-pt-lg" v-show="showCropper">
        <q-btn @click="closeCropper()" class="button-custom-color btn-modal-finish q-mb-md on-left" flat>Cancel</q-btn>
        <q-btn @click="cropAndSave()" class="button-custom-color btn-modal-finish q-mb-md" :loading="savingCompanyLogo" color="primary">Save</q-btn>
      </div>
    </div>

    <!-- logo -->
    <div class="row">
      <div class="col-xs-12 col-md-8 gutter-xs q-my-xl">
        <q-card flat class="bg-lmsBackground">
          <q-card-section>
            <div class="row">
              <div class="col">
                <div class="text-h5">Logo</div>
                <div class="text-subtitle2">Set your company's logo. Recommended size (300x65) *Larger logos will be resized to 300 pixel width</div>
              </div>
              <div class="col-auto">
                <label class="file-select cursor-pointer">
                  <q-btn flat round icon="edit" size="16px" @click="editLogo" :loading="savingCompanyLogo">
                    <div class="form-error-msg hidden text-negative">An image is required.</div>
                  </q-btn>
                  <input @input="openCropper" id="editLogo" type="file" name="pic" accept="image/*" icon="edit">
                </label>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="shadow-2 bg-lmsHeader" style="display: flex; justify-content: center; height: 200px;">
            <q-img :src="($store.getters['auth/companyLogo'] ? $store.getters['auth/companyLogo'] : '/statics/general/midstates-logo-red-white.png')" style="max-width: 30vw; height:auto;" contain/>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Cropper from 'cropperjs'

export default {
  data () {
    return {
      cropper: false,
      showCropper: false,
      savingCompanyLogo: false,
      timeZoneOptions: [
        { label: 'Eastern', value: 'US/Eastern' },
        { label: 'Central', value: 'US/Central' },
        { label: 'Mountain', value: 'US/Mountain' },
        { label: 'Pacific', value: 'US/Pacific' }
      ]
    }
  },
  computed: {
    enableMessages: {
      get () {
        return this.$store.state.auth.user.company.settings.enableMessages
      },
      set (data) {
        this.updateSettings('enableMessages', data)
      }
    },
    enableSubmittedContent: {
      get () {
        return this.$store.state.auth.user.company.settings.enableSubmittedContent
      },
      set (data) {
        this.updateSettings('enableSubmittedContent', data)
      }
    },
    enableUserTheme: {
      get () {
        return this.$store.state.auth.user.company.settings.enableUserTheme
      },
      set (data) {
        this.updateSettings('enableUserTheme', data)
      }
    },
    homePageFilter: {
      get () {
        return this.$store.state.auth.user.company.home_page_filter
      },
      set (data) {
        this.$q.loading.show()
        this.$store.dispatch('auth/updateHomePageFilter', data).then((resp) => {
          this.$q.loading.hide()
        })
      }
    },
    currentTheme: {
      get: function () {
        return (this.$store.getters['auth/customerTheme'] !== null) ? this.$store.getters['auth/customerTheme'].uid : null
      },
      set: function (newThemeUid) {
        if (newThemeUid !== this.currentTheme) {
          this.$q.loading.show()
          this.$store.dispatch('auth/updateCustomerTheme', { theme_uid: newThemeUid }).then((resp) => {
            if (this.$store.getters['auth/userTheme'] === null) {
              this.$setTheme(resp)
            }
            this.$q.loading.hide()
            this.$successMsg('Company Theme Updated')
          }).catch((e) => {
            this.$q.loading.hide()
            this.$failureMsg('Failed to update Company Theme')
          })
        }
      }
    },
    timezone: {
      get () {
        return this.$store.getters['auth/timezone']
      },
      set (timezone) {
        this.$q.loading.show()
        this.$store.dispatch('auth/updateCompanyTimeZone', timezone).then(() => {
          this.$q.loading.hide()
          this.$successMsg('Timezone Updated')
        }).catch((e) => {
          this.$q.loading.hide()
          this.$failureMsg()
        })
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      uploadCustomerFile: 'auth/uploadCustomerFile'
    }),
    editLogo () {
      document.getElementById('editLogo').click()
    },
    closeCropper () {
      this.savingCompanyLogo = false
      document.getElementById('editLogo').value = null
      document.getElementById('croppedLogo').src = ''
      document.getElementById('cropperContainer').style.height = '0px'
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
      this.showCropper = false
    },
    openCropper (e) {
      this.showCropper = true
      let tFile = e.target.files[0]

      if (!tFile) {
        return
      }

      let URL = window.URL || window.webkitURL
      let tURL = URL.createObjectURL(tFile)

      document.getElementById('croppedLogo').src = tURL
      document.getElementById('cropperContainer').style.height = (window.outerHeight * 0.5) + 'px'
      if (!this.$refs.editLogo) {
        return
      }
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = false
      }
      let cropper = new Cropper(this.$refs.editLogo, {
        viewMode: 1,
        responsive: true,
        autoCropArea: 1,
        dragMode: 'move'
      })
      this.cropper = cropper
    },
    cropAndSave () {
      let blob = this.cropper.getCroppedCanvas({ width: 300 })
      var uA = window.navigator.userAgent
      var isIE = /msie\s|trident\/|edge\//i.test(uA) && !!(document.uniqueID || document.documentMode || window.ActiveXObject || window.MSInputMethodContext)
      if (isIE) {
        this.saveLogo(blob.msToBlob())
      } else {
        blob.toBlob((blob) => {
          this.saveLogo(blob)
        }, 'image/png', 1)
      }
    },
    async saveLogo (blobFile) {
      this.savingCompanyLogo = true
      let payload = {
        file: blobFile,
        type: 'logo',
        size: [600, 128]
      }

      try {
        let res = await this.$store.dispatch('auth/uploadCustomerFile', payload)
        await this.$store.dispatch('auth/updateCompanyLogo', res.path)

        this.savingCompanyLogo = false
        document.getElementById('editLogo').value = null
        document.getElementById('croppedLogo').src = ''
        document.getElementById('cropperContainer').style.height = '0px'

        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }

        this.showCropper = false
        this.$successMsg('Logo Updated')
      } catch (e) {
        this.savingCompanyLogo = false
        this.$failureMsg(`Failed to update the logo`)
      }
    },
    async updateSettings (type, value) {
      this.$loading(true)

      try {
        await this.$store.dispatch('auth/updateSettings', { type: type, value: value })
        this.$loading(false)
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    }
  },
  async beforeCreate () {
    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)
  }
}
</script>

<style lang="stylus" scoped>
.file-select > input[type="file"]
  display none
</style>
